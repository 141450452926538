import { signalStoreFeature, withComputed, withState } from '@ngrx/signals';
import { CallStateEnum } from '@models/call-state.model';
import { computed } from '@angular/core';

export function withCallState() {
  return signalStoreFeature(
    withState<{ callState: CallStateEnum; error: Error }>({
      callState: CallStateEnum.Init,
      error: null
    }),
    withComputed(({ callState, error }) => ({
      isLoading: computed(() => callState() === CallStateEnum.Loading),
      isLoaded: computed(() => callState() === CallStateEnum.Loaded),
      isError: computed(() => callState() === CallStateEnum.Error)
    }))
  );
}
