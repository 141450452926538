export class PromoBannerModel {
  id: string;
  dateCreated: Date;
  dateModified: Date | null;
  desktopImg: PromoBannerImgModel;
  desktopImgId: string;
  endDate: Date | null;
  mobileImg: PromoBannerImgModel;
  mobileImgId: string;
  name: string;
  order: number;
  startDate: Date | null;
  status: string;

  static fromJSON(json: any): PromoBannerModel {
    return {
      id: json._id,
      dateCreated: json.date_created,
      dateModified: json.date_modified || null,
      desktopImg: PromoBannerImgModel.fromJSON(json.desktop_img),
      desktopImgId: json.desktop_img_id,
      endDate: json.end_date || null,
      mobileImg: PromoBannerImgModel.fromJSON(json.mobile_img),
      mobileImgId: json.mobile_img_id,
      name: json.name,
      order: json.order,
      startDate: json.start_date || null,
      status: json.status
    };
  }
}

export class PromoBannerImgModel {
  accountId: number;
  createdBy: number;
  dateCreated: Date;
  ext: string;
  filename: string;
  hash: string;
  height: number;
  isPortrait: boolean;
  path: string;
  purpose: string;
  size: number;
  status: string;
  width: number;

  static fromJSON(json: any): PromoBannerImgModel {
    return {
      accountId: json.account_id,
      createdBy: json.created_by,
      dateCreated: json.date_created,
      ext: json.ext,
      filename: json.filename,
      hash: json.hash,
      height: json.height,
      isPortrait: json.is_portrait,
      path: json.path,
      purpose: json.purpose,
      size: json.size,
      status: json.status,
      width: json.width
    };
  }
}
