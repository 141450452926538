export class NetVerifiedCountriesModel {
  id: string;
  countryCode: string;
  countryName: string;
  idTypes: ReadonlyArray<{ idType: NetVerifiedCountriesIdTypeEnum }>;

  static fromJson(json: any): NetVerifiedCountriesModel {
    return {
      id: json._id,
      countryCode: json.countryCode,
      countryName: json.countryName,
      idTypes: json.idTypes
    };
  }
}

export enum NetVerifiedCountriesIdTypeEnum {
  IdCard = 'ID_CARD',
  DrivingLicense = 'DRIVING_LICENSE',
  Passport = 'PASSPORT',
  ResidencePermit = 'RESIDENCE_PERMIT'
}
