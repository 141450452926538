import { HttpEvent, HttpEventType } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { EventMessageStatusEnum } from '@models/image.model';

export function createFormData(file: File): FormData {
  const formData = new FormData();
  formData.append('files[]', file, file.name);
  return formData;
}

export function getEventMessage(
  event: HttpEvent<any>,
  file: File,
  cId: string,
  translateService: TranslateService
) {
  switch (event.type) {
    case HttpEventType.Sent:
      return {
        msg: translateService.instant('event_message_uploading_file_size', {
          fileName: file.name,
          fileSize: file.size
        }),
        status: EventMessageStatusEnum.Pending,
        percentDone: 0,
        body: null,
        cId: cId
      };

    case HttpEventType.UploadProgress:
      const percentDone = Math.round((100 * event.loaded) / event.total);
      return {
        msg: translateService.instant(
          'event_message_uploading_file_percent_done',
          {
            fileName: file.name,
            percentDone
          }
        ),
        percentDone: percentDone,
        status: EventMessageStatusEnum.Pending,
        body: null,
        cId: cId
      };

    case HttpEventType.Response:
      return {
        msg: translateService.instant('event_message_uploading_file_uploaded', {
          fileName: file.name
        }),
        percentDone: 100,
        status: EventMessageStatusEnum.Finished,
        body: event.body,
        cId: cId
      };

    default:
      return {
        msg: translateService.instant('event_message_uploading_file_finished', {
          fileName: file.name,
          fileSize: file.size
        }),
        status: EventMessageStatusEnum.Pending,
        percentDone: 100,
        body: null,
        cId: cId
      };
  }
}
