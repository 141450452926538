export interface ICallState {
  callState: CallStateEnum;
  error: Error | null;
}

export enum CallStateEnum {
  Init = 'INIT',
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Error = 'ERROR'
}

export const initialCallState: ICallState = {
  callState: CallStateEnum.Init,
  error: null
};
