import { ValidPatterModel } from '@models/profile-form-data.model';
import { ThumbNameEnum } from '@models/image.model';

export class MediaConfigsModel {
  email: Pick<ValidPatterModel, 'validPattern'>;
  weekDays: string[];
  objectId: Pick<ValidPatterModel, 'validPattern'>;
  url: Pick<ValidPatterModel, 'validPattern'>;
  phone: Omit<ValidPatterModel, 'allowedTags' | 'allowedAttributes'>;
  callingCode: Omit<ValidPatterModel, 'allowedTags' | 'allowedAttributes'>;
  supportedLangs: string[];
  aaVersion: number;
  currencies: string[];
  ipv4Regex: string;
  shredderAccountNoServiceThreshold: number;
  shredderAccountInactivityThreshold: number;
  name: string;
  uploadDir: string;
  coconutApiKey: string;
  coconutTokenSecret: string;
  timeout: ITimeout;
  fileSize: IFileSize;
  fieldName: string;
  profileImageMimetypes: string[];
  bannerImageMimetypes: string[];
  promoBannerImageMimetypes: string[];
  videoMimetypes: string[];
  videoWatermarkPosition: string;
  videoDuration: Pick<ValidPatterModel, 'min'>;
  videoHeight: Pick<ValidPatterModel, 'min'>;
  videoWidth: Pick<ValidPatterModel, 'min'>;
  profileImageWidth: Pick<ValidPatterModel, 'min' | 'max'>;
  profileImageHeight: Pick<ValidPatterModel, 'min' | 'max'>;
  profilePortraitImageWidth: Pick<ValidPatterModel, 'min'>;
  profilePortraitImageHeight: Pick<ValidPatterModel, 'min' | 'max'>;
  profileLandscapeImageHeight: Pick<ValidPatterModel, 'min'>;
  profileLandscapeImageWidth: Pick<ValidPatterModel, 'min' | 'max'>;
  imageMaxSize: number;
  imageQuality: number;
  purposes: MediaPurposeEnum[];
  purposesToResize: string[];
  documentPurposes: string[];
  documentMimetypes: string[];
  documentIdMimetypes: string[];
  storyImageMimetypes: string[];
  documentTest: number;
  resizeThumbs: ThumbNameEnum[];
  thumbSizes: IThumbSizes;

  static fromJson(json: any): MediaConfigsModel {
    return {
      email: ValidPatterModel.fromJson(json.email),
      weekDays: json.week_days,
      objectId: ValidPatterModel.fromJson(json.object_id),
      url: ValidPatterModel.fromJson(json.url),
      phone: ValidPatterModel.fromJson(json.phone),
      callingCode: ValidPatterModel.fromJson(json.calling_code),
      supportedLangs: json.supportedLangs,
      aaVersion: json.aa_version,
      currencies: json.currencies,
      ipv4Regex: json.ipv4_regex,
      shredderAccountNoServiceThreshold:
        json.shredder_account_no_service_threshold,
      shredderAccountInactivityThreshold:
        json.shredder_account_inactivity_threshold,
      name: json.name,
      uploadDir: json.upload_dir,
      coconutApiKey: json.coconut_api_key,
      coconutTokenSecret: json.coconut_token_secret,
      timeout: json.timeout,
      fileSize: json.file_size,
      fieldName: json.fieldname,
      profileImageMimetypes: json.profile_image_mimetypes,
      bannerImageMimetypes: json.banner_image_mimetypes,
      promoBannerImageMimetypes: json.promo_banner_image_mimetypes,
      videoMimetypes: json.video_mimetypes,
      videoWatermarkPosition: json.video_watermark_position,
      videoDuration: ValidPatterModel.fromJson(json.video_duration),
      videoHeight: ValidPatterModel.fromJson(json.video_height),
      videoWidth: ValidPatterModel.fromJson(json.video_width),
      profileImageWidth: ValidPatterModel.fromJson(json.profile_image_width),
      profileImageHeight: ValidPatterModel.fromJson(json.profile_image_height),
      profilePortraitImageWidth: ValidPatterModel.fromJson(
        json.profile_portrait_image_width
      ),
      profilePortraitImageHeight: ValidPatterModel.fromJson(
        json.profile_portrait_image_height
      ),
      profileLandscapeImageHeight: ValidPatterModel.fromJson(
        json.profile_landscape_image_height
      ),
      profileLandscapeImageWidth: ValidPatterModel.fromJson(
        json.profile_landscape_image_width
      ),
      imageMaxSize: json.image_max_size,
      imageQuality: json.image_quality,
      purposes: json.purposes,
      purposesToResize: json.purposes_to_resize,
      documentPurposes: json.document_purposes,
      documentMimetypes: json.document_mimetypes,
      documentIdMimetypes: json.document_id_mimetypes,
      storyImageMimetypes: json.story_image_mimetypes,
      documentTest: json.document_test,
      resizeThumbs: json.resize_thumbs,
      thumbSizes: json.thumb_sizes
    };
  }
}

export interface ITimeout {
  image: string;
  document: string;
  video: string;
}

export interface IFileSize {
  image: number;
  document: number;
  video: number;
}

export interface IThumbSizes {
  orig: IThumbSizeOrig;
}

export interface IThumbSizeOrig {
  _child: IThumbSizeOrigChild[];
}

export interface IThumbSizeOrigChild {
  name: string;
  width: number;
  height: number;
  action: string;
  watermark?: string;
  _child?: IThumbSizeOrigChild[];
}

export enum MediaPurposeEnum {
  Profile = 'profile',
  Banner = 'banner',
  PromoBanner = 'promo_banner',
  Story = 'story',
  Verification = 'verification'
}
