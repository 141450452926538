import { PremiumAdModel, PremiumAdStatusEnum } from '@models/premium-ad.model';
import { PremiumServiceListModel } from '@app/pages/my/features/services/data-access/models/all-services.model';

export class CommerceOptionsFormDataModel {
  premiumAdRenewOptions: IPremiumAdRenewOptions;
  premiumServiceRenewOptions: IRenewOptions;
  manualServiceRenewOptions: IRenewOptions;
  manualServices: IManualServices;
  banner: IBanner;
  debitReasons: string[];
  creditReasons: string[];
  refundReasons: string[];
  refundReasonsNoteRequired: string[];
  paymentMethods: string[];
  paymentGroups: string[];
  creditCard: string[];
  currencies: string[];
  rocketGateMaxSavedCcCount: number;
  bitcoinMinAmount: number;
  bitcoinMaxAmount: number;
  ePinMinAmount: number;
  rocketGateIovation: IRocketGateIovation;
  premiumAdShortTermExtendDurations: number[];
  premiumAdShortTermExtendPriceMultiplier: number;
  premiumAdDateActivationMaximumDelay: number;
  premiumAdDateActivationMaxPostponeAttempts: number;
  deposit2bp: IDeposit2bp;

  static fromJson(json: any): CommerceOptionsFormDataModel {
    return {
      premiumAdRenewOptions: {
        base: {
          adminDurations: json.premium_ad_renew_options?.base.admin_durations,
          customerDurations:
            json.premium_ad_renew_options?.base.customer_durations
        },
        visiting: {
          adminDurations:
            json.premium_ad_renew_options?.visiting.admin_durations,
          customerDurations:
            json.premium_ad_renew_options?.visiting.customer_durations
        }
      },
      premiumServiceRenewOptions: {
        adminDurations: json.premium_service_renew_options.admin_durations,
        customerDurations: json.premium_service_renew_options.customer_durations
      },
      manualServiceRenewOptions: {
        adminDurations: json.manual_service_renew_options.admin_durations,
        customerDurations: json.manual_service_renew_options.customer_durations
      },
      manualServices: json.manual_services,
      banner: {
        imageExtensions: json.banner.image_extensions,
        imageMaxSize: json.banner.image_max_size,
        url: {
          ...json.banner.url,
          validPattern: json.banner.url.valid_pattern
        }
      },
      debitReasons: json.debit_reasons,
      creditReasons: json.credit_reasons,
      refundReasons: json.refund_reasons,
      refundReasonsNoteRequired: json.refund_reasons_note_required,
      paymentMethods: json.payment_methods,
      paymentGroups: json.payment_groups,
      creditCard: json.credit_card,
      currencies: json.currencies,
      rocketGateMaxSavedCcCount: json.rocketgate_max_saved_cc_count,
      bitcoinMinAmount: json.bitcoin_min_amount,
      bitcoinMaxAmount: json.bitcoin_max_amount,
      ePinMinAmount: json.epin_min_amount,
      rocketGateIovation: {
        ioBboutElementId: json.rocketgate_iovation?.io_bbout_element_id,
        ioEnableRip: json.rocketgate_iovation?.io_enable_rip,
        ioInstallStm: json.rocketgate_iovation?.io_install_stm,
        ioExcludeStm: json.rocketgate_iovation?.io_exclude_stm,
        ioInstallFlash: json.rocketgate_iovation?.io_install_flash,
        scriptUrl: json.rocketgate_iovation?.script_url,
        scriptUrlDev: json.rocketgate_iovation?.script_url_dev
      },
      premiumAdShortTermExtendDurations:
        json.premium_ad_short_term_extend_durations,
      premiumAdShortTermExtendPriceMultiplier:
        json.premium_ad_short_term_extend_price_multiplier,
      premiumAdDateActivationMaximumDelay:
        json.premium_ad_date_activation_maximum_delay,
      premiumAdDateActivationMaxPostponeAttempts:
        json.premium_ad_date_activation_max_postpone_attemps,
      deposit2bp: {
        minAmount: json.deposit2bp.min_amount,
        maxAmount: json.deposit2bp.max_amount
      }
    };
  }
}

interface IPremiumAdRenewOptions {
  visiting: IRenewOptions;
  base: IRenewOptions;
}

interface IRenewOptions {
  adminDurations: number[];
  customerDurations: number[];
}

interface IManualServices {
  list: IList[];
  renewables: string[];
  hidden: string[];
}

interface IList {
  id: number;
  name: string;
}

interface IBanner {
  url: IUrl;
  imageExtensions: string[];
  imageMaxSize: number;
}

interface IUrl {
  validPattern: string;
}

interface IRocketGateIovation {
  ioBboutElementId: string;
  ioEnableRip: boolean;
  ioInstallStm: boolean;
  ioExcludeStm: number;
  ioInstallFlash: boolean;
  scriptUrl: string;
  scriptUrlDev: string;
}

interface IDeposit2bp {
  minAmount: number;
  maxAmount: number;
}

export class ShoppingCartModel {
  accountId: number;
  amount: number;
  bumps: any[];
  currency: string;
  dateCreated: Date;
  id: number;
  isSystem: number;
  manualServices: any[];
  premiumAds: PremiumAdModel[];
  premiumServices: PremiumServiceListModel[];
  systemAmount: number;

  static fromJson(json: any): ShoppingCartModel {
    return {
      accountId: json.account_id,
      amount: json.amount,
      bumps: json.bumps,
      currency: json.currency,
      dateCreated: new Date(json.date_created),
      id: json.id,
      isSystem: json.is_system,
      manualServices: json.manual_services,
      premiumAds: json.premium_ads
        ? json.premium_ads.map((premiumAd: PremiumAdModel) =>
            PremiumAdModel.fromJson(premiumAd)
          )
        : [],
      premiumServices: json.premium_services
        ? json.premium_services.map((premiumService: PremiumServiceListModel) =>
            PremiumServiceListModel.fromJSON(premiumService)
          )
        : [],
      systemAmount: json.system_amount
    };
  }
}

export type FindPremiumAdReqPayload = {
  accountId: number;
  adIds?: string[];
  status?: PremiumAdStatusEnum[];
};

export enum CryptocurrencyEnum {
  Usdt = 'usdt',
  Bitcoin = 'btc'
}

export type PurchaseUrlWithAmountWithCurrencyReqPayload = {
  paymentUrl: string;
  accountId: number;
  amount: string;
  currency?: CryptocurrencyEnum;
};

export interface ExternalPostData {
  actionUrl: string;
  attrs: { [s: string]: any };
}

export interface PaidTransaction {
  paymentId: number;
  amount: number;
  address: string;
  ref_id: number;
  currency: string;
}

export interface IBalance {
  balance: number;
  currency?: string;
}

export interface ISocketBalance {
  account_id: string;
  balance_after: number;
}

export class OrderModel {
  accountId: number;
  amount: number;
  bumps: any[];
  balanceAfter: number;
  createdBy: number;
  creditApplied: number;
  currency: string;
  dateCreated: Date | string;
  id: number;
  isAutobilled: 0 | 1;
  manualServiceRenewals: any[];
  manualServices: any[];
  paidAmount: number;
  paymentId: number | null;
  premiumAds: PremiumAdModel[];
  premiumServiceRenewals: PremiumServiceListModel[];
  premiumServices: PremiumServiceListModel[];
  systemAmount: number;

  static fromJson(json: any): OrderModel {
    return {
      accountId: json.account_id,
      amount: json.amount,
      bumps: json.bumps,
      balanceAfter: json.balance_after,
      createdBy: json.created_by,
      creditApplied: json.credit_applied,
      currency: json.currency,
      dateCreated: new Date(json.date_created),
      id: json.id,
      isAutobilled: json.is_autobilled,
      manualServiceRenewals: json.manual_service_renewals,
      manualServices: json.manual_services,
      paidAmount: json.paid_amount,
      paymentId: json.payment_id ?? null,
      premiumAds: json.premium_ads
        ? json.premium_ads.map((premiumAd: PremiumAdModel) =>
            PremiumAdModel.fromJson(premiumAd)
          )
        : [],
      premiumServiceRenewals: json.premium_service_renewals
        ? json.premium_service_renewals.map(
            (premiumService: PremiumServiceListModel) =>
              PremiumServiceListModel.fromJSON(premiumService)
          )
        : [],
      premiumServices: json.premium_services
        ? json.premium_services.map((premiumService: PremiumServiceListModel) =>
            PremiumServiceListModel.fromJSON(premiumService)
          )
        : [],
      systemAmount: json.system_amount
    };
  }
}

export class OrderInvoiceModel {
  amount: number;
  balanceAfter: number;
  creditApplied: number;
  currency: string;
  dateCreated: Date | string;
  discountedAmount: number;
  email: string;
  fee: number;
  finalAmount: number;
  gateway: string;
  orderId: number;
  paymentType: string;
  services: OrderInvoiceServicesModel[];
  totalPaid: number;
  creditCardType: number | null;
  creditCardLastFourDigits: number | null;

  static fromJson(json: any): OrderInvoiceModel {
    return {
      amount: json.amount,
      balanceAfter: json.balance_after,
      creditApplied: json.credit_applied,
      currency: json.currency,
      dateCreated: new Date(json.date_created),
      discountedAmount: json.discounted_amount,
      email: json.email,
      fee: json.fee,
      finalAmount: json.final_amount,
      gateway: json.gateway,
      orderId: json.order_id,
      paymentType: json.payment_type,
      services: json.services.map((service: OrderInvoiceServicesModel) =>
        OrderInvoiceServicesModel.fromJson(service)
      ),
      totalPaid: json.total_paid,
      creditCardType: json.credit_card_type,
      creditCardLastFourDigits: json.credit_card_last_four_digits
    };
  }
}

export class OrderInvoiceServicesModel {
  adId: string | null;
  amount: number;
  dateActivation: Date | string;
  dateExpiration: Date | string;
  discountPercentage: number | null;
  discountedAmount: number;
  exchangeRate: number;
  finalAmount: number;
  id: string;
  market: string;
  name: string;

  dateActivationFormatted?: Date | string;
  dateExpirationFormatted?: Date | string;

  static fromJson(json: any): OrderInvoiceServicesModel {
    return {
      adId: json.ad_id,
      amount: json.amount,
      dateActivation: json.date_activation,
      dateExpiration: json.date_expiration,
      discountPercentage: json.discount_percentage,
      discountedAmount: json.discounted_amount,
      exchangeRate: json.exchange_rate,
      finalAmount: json.final_amount,
      id: json.id,
      market: json.market,
      name: json.name
    };
  }
}

export enum ParamsGatewayEnum {
  Twispay = 'twispay',
  Bitcoinpay = 'bitcoinpay',
  Inovio = 'inovio',
  Unicorn = 'unicorn',
  Emerchantpay = 'emerchantpay'
}

export enum ParamsGatewayStatus {
  Success = 'success',
  Pending = 'pending',
  Failed = 'failed'
}
