import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState
} from '@ngrx/signals';
import {
  FORM_CONFIGS_INITIAL_DATA,
  IFormConfigs
} from '@app/store/form-configs/form-configs.model';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { forkJoin, pipe } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { computed, inject } from '@angular/core';
import { EscortProfileService } from '@api/escort-profile.service';
import { MediaService } from '@api/media.service';
import { CommerceService } from '@api/commerce.service';
import { AccountService } from '@api/account.service';
import { tapResponse } from '@ngrx/operators';
import { withCallState } from '@shared/store-features/call-state-feature';
import { CallStateEnum } from '@models/call-state.model';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { TranslateService } from '@ngx-translate/core';

export const FormConfigsStore = signalStore(
  { providedIn: 'root' },
  withDevtools('form-configs'),
  withState<IFormConfigs>(FORM_CONFIGS_INITIAL_DATA),
  withComputed(
    (
      { profileFormDataConfigs, mediaConfigs },
      translateService = inject(TranslateService)
    ) => ({
      genders: computed(
        () => profileFormDataConfigs()?.attributes.gender.values ?? []
      ),
      transOperationGenders: computed(
        () => profileFormDataConfigs()?.configs.transOperationGenders ?? []
      ),
      opStatuses: computed(() => [
        ...(profileFormDataConfigs()?.attributes.opStatus.values.map(
          (status) => ({
            title: status,
            value: status
          })
        ) ?? []),
        { value: null, title: translateService.instant('global_na') }
      ]),
      measurements: computed(
        () => profileFormDataConfigs()?.configs.unitTypes ?? []
      ),
      availableTo: computed(
        () => profileFormDataConfigs()?.attributes.availability.values ?? []
      ),
      ethnicities: computed(
        () => profileFormDataConfigs()?.attributes.ethnicity.values ?? []
      ),
      eyeColors: computed(
        () => profileFormDataConfigs()?.attributes.eyeColor.values ?? []
      ),
      hairColors: computed(
        () => profileFormDataConfigs()?.attributes.hairColor.values.sort() ?? []
      ),
      cupSizes: computed(
        () => profileFormDataConfigs()?.attributes.cupSize.values.sort() ?? []
      ),
      affiliation: computed(
        () => profileFormDataConfigs()?.attributes.affiliation.values
      ),
      showNameMaxLength: computed(
        () => profileFormDataConfigs()?.configs.showName.max ?? 0
      ),
      emailMaxLength: computed(() => 254),
      taglineMaxLength: computed(
        () => profileFormDataConfigs()?.configs.tagline.max ?? 0
      ),
      profileImageMimetypes: computed(() => [
        ...(mediaConfigs()?.profileImageMimetypes ?? []),
        'image/jpg'
      ]),
      photoDimensions: computed(() => ({
        minWidth: mediaConfigs()?.profileImageWidth.min,
        minHeight: mediaConfigs()?.profileImageHeight.min,
        maxWidth: mediaConfigs()?.profileImageWidth.max,
        maxHeight: mediaConfigs()?.profileImageHeight.max
      }))
    })
  ),
  withCallState(),
  withMethods(
    (
      store,
      escortProfileService = inject(EscortProfileService),
      mediaService = inject(MediaService),
      accountService = inject(AccountService),
      commerceService = inject(CommerceService)
    ) => ({
      loadFormConfigs: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { callState: CallStateEnum.Loading })),
          switchMap(() =>
            forkJoin([
              escortProfileService.getProfileFormData(),
              mediaService.getMediaConfigs(),
              accountService.getAccountFormData(),
              commerceService.getCommerceOptions()
            ]).pipe(
              tapResponse({
                next: ([
                  profileFormDataConfigs,
                  mediaConfigs,
                  accountFormDataConfigs,
                  commerceOptionsConfigs
                ]) => {
                  patchState(store, {
                    callState: CallStateEnum.Loaded,
                    profileFormDataConfigs,
                    mediaConfigs,
                    accountFormDataConfigs,
                    commerceOptionsConfigs
                  });
                },

                error: () =>
                  patchState(store, { callState: CallStateEnum.Error })
              })
            )
          )
        )
      )
    })
  ),
  withHooks({
    onInit({ loadFormConfigs }) {
      loadFormConfigs();
    }
  })
);
