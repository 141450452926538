import { Inject, inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import {
  CommerceOptionsFormDataModel,
  ExternalPostData,
  FindPremiumAdReqPayload,
  IBalance,
  OrderInvoiceModel,
  OrderModel,
  PaidTransaction,
  PurchaseUrlWithAmountWithCurrencyReqPayload,
  ShoppingCartModel
} from '@models/commerce-options-form-data.model';
import { PremiumAdModel } from '@models/premium-ad.model';
import { DOCUMENT } from '@angular/common';
import { PremiumServiceListModel } from '@app/pages/my/features/services/data-access/models/all-services.model';
import {
  IPremierServiceAddToBagReqBody,
  IPrSrGirlOfTheDayAddToBagReqBody
} from '@models/premier-service.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommerceService {
  #httpClient = inject(HttpClient);
  #baseUrl = `${environment.apiUrl}/commerce`;

  document = inject(DOCUMENT);

  getCommerceOptions(): Observable<CommerceOptionsFormDataModel> {
    const url = `${this.#baseUrl}/options`;

    return this.#httpClient
      .get<CommerceOptionsFormDataModel>(url)
      .pipe(map((json: any) => CommerceOptionsFormDataModel.fromJson(json)));
  }

  getShoppingCart(accountId: number): Observable<ShoppingCartModel> {
    const url = `${this.#baseUrl}/cart/${accountId}`;
    return this.#httpClient
      .get(url)
      .pipe(map((res) => ShoppingCartModel.fromJson(res)));
  }

  findPremiumAdList({
    accountId,
    adIds,
    status
  }: FindPremiumAdReqPayload): Observable<PremiumAdModel[]> {
    const url = `${this.#baseUrl}/premium-ad/_bulk`;
    const body = {
      account_id: accountId,
      ad_ids: adIds,
      status: status
    };

    return this.#httpClient
      .post<PremiumAdModel[]>(url, body)
      .pipe(map((list) => list.map((item) => PremiumAdModel.fromJson(item))));
  }

  getPremiumAdById(id: number): Observable<PremiumAdModel> {
    const url = `${this.#baseUrl}/premium-ad/${id}`;

    return this.#httpClient
      .get<PremiumAdModel>(url)
      .pipe(map((json: any) => PremiumAdModel.fromJson(json)));
  }

  generateBitCoinUrl(): Observable<{ payment_url: string }> {
    const url = `${this.#baseUrl}/bitcoin/payment-url`;
    return this.#httpClient.get<{ payment_url: string }>(url);
  }

  getRecommendedEmerchantpayUrl(
    accountId: number
  ): Observable<{ url: string }> {
    const url = `${this.#baseUrl}/emerchantpay/payment-url`;

    const params = new HttpParams().set('account_id', accountId.toString());
    return this.#httpClient.get<{ url: string }>(url, { params });
  }

  payZeroAmount(accountId: number): Observable<{}> {
    const url = `${this.#baseUrl}/cart/${accountId}/_pay`;

    return this.#httpClient.post<{}>(url, {});
  }

  getPurchaseUrlWithAmountWithCurrency(
    payload: PurchaseUrlWithAmountWithCurrencyReqPayload
  ): Observable<{ payment_url: string }> {
    const url = `${this.#baseUrl}${payload.paymentUrl}`;
    let params = new HttpParams().set(
      'account_id',
      payload.accountId.toString()
    );

    if (payload.amount) {
      params = params.set('amount', payload.amount);
    }

    if (payload.currency) {
      params = params.set('currency', payload.currency);
    }

    return this.#httpClient.get<{ payment_url: string }>(url, { params });
  }

  getPremiumServiceList(
    accountId: number
  ): Observable<PremiumServiceListModel[]> {
    const url = `${this.#baseUrl}/cart/${accountId}/premium-service/list`;

    return this.#httpClient
      .get<PremiumServiceListModel[]>(url)
      .pipe(
        map((res) => res.map((item) => PremiumServiceListModel.fromJSON(item)))
      );
  }

  postToExternalSite(externalPostData: ExternalPostData): void {
    const form = this.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', externalPostData.actionUrl);

    const attrs = Object.assign({}, externalPostData.attrs);

    Object.keys(attrs).forEach((key) => {
      form.appendChild(this.createHiddenInputElement(key, attrs[key]));
    });

    this.document.body.appendChild(form);
    form.submit();
  }

  buyPremiumService(
    value: IPremierServiceAddToBagReqBody
  ): Observable<unknown> {
    const url = `${this.#baseUrl}/cart/premium-service`;
    return this.#httpClient.post(url, value);
  }

  createPremiumServiceGirlOfTheDay(
    value: IPrSrGirlOfTheDayAddToBagReqBody
  ): Observable<any> {
    const url = `${this.#baseUrl}/cart/premium-service/gotd`;

    return this.#httpClient.post(url, value).pipe();
  }

  getCountOfGeneratedPayments(
    accountId: number
  ): Observable<{ count: number }> {
    const url = `${this.#baseUrl}/coinfly`;

    const params = new HttpParams().set('account_id', accountId.toString());

    return this.#httpClient.get<{ count: number }>(url, {
      params
    });
  }

  getListOfGeneratedPayments(
    accountId: number
  ): Observable<Array<PaidTransaction>> {
    const url = `${this.#baseUrl}/coinfly`;

    return this.#httpClient
      .post(url, {
        account_id: accountId
      })
      .pipe(map((json: { payments: Array<PaidTransaction> }) => json.payments));
  }

  removeGeneratedPayments(accountId: number): Observable<{}> {
    const url = `${this.#baseUrl}/coinfly/_bulk`;

    const params = new HttpParams().set('account_id', accountId.toString());

    return this.#httpClient.delete<{}>(url, {
      params
    });
  }

  getBalance(accountId: number): Observable<IBalance> {
    const url = `${this.#baseUrl}/${accountId}/balance`;

    return this.#httpClient.get<IBalance>(url);
  }

  getOrderByRef(refId: number): Observable<OrderModel> {
    const url = `${this.#baseUrl}/order/ref/${refId}`;

    return this.#httpClient.get<OrderModel>(url).pipe(
      map((order) => OrderModel.fromJson(order)),
      catchError((err) => throwError(err))
    );
  }

  getOrderInvoiceDataByOrderId(orderId: number): Observable<OrderInvoiceModel> {
    const url = `${this.#baseUrl}/order/${orderId}/invoice`;

    return this.#httpClient.get<OrderInvoiceModel>(url).pipe(
      map((order) => OrderInvoiceModel.fromJson(order)),
      catchError((err) => throwError(err))
    );
  }

  private createHiddenInputElement(
    name: string,
    value: string
  ): HTMLInputElement {
    const hiddenField = this.document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }
}
