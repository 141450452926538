import { inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { map, Observable, of, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { MediaConfigsModel, MediaPurposeEnum } from '@models/media.model';
import { ImageModel, IUploadFileRes, ThumbNameEnum } from '@models/image.model';
import { catchError } from 'rxjs/operators';
import { UploadProfileImageReqPayload } from '@models/image.model';
import { createFormData, getEventMessage } from '@utils/create-form-data';
import { PromoBannerModel } from '@models/promo-banner.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  #httpClient = inject(HttpClient);
  #baseUrl = `${environment.apiUrl}/media`;
  #translateService = inject(TranslateService);

  getMediaConfigs(): Observable<MediaConfigsModel> {
    const url = `${this.#baseUrl}/configs`;

    return this.#httpClient
      .get<MediaConfigsModel>(url)
      .pipe(map((json: any) => MediaConfigsModel.fromJson(json)));
  }

  findImagesByIds(
    ids: string[],
    thumbName: ThumbNameEnum
  ): Observable<ImageModel[]> {
    const url = `${this.#baseUrl}/images/_bulk`;

    const body = { ids, thumb_name: thumbName };

    return this.#httpClient.post(url, body).pipe(
      map((json: any[]) => {
        return json.map((item) => {
          if (item.is_adjusted) {
            item.path = item.path + '?' + Math.random();
          }

          return ImageModel.fromJson(item);
        });
      })
    );
  }

  uploadProfileImage(
    payload: UploadProfileImageReqPayload
  ): Observable<{ imageId: string; cId: string }> {
    const url = `${this.#baseUrl}/images`;
    const purpose = payload.purpose ?? 'profile';

    const params = new HttpParams()
      .set('purpose', purpose)
      .set('account_id', payload.accountId.toString());

    const formData = createFormData(payload.file);

    return this.#httpClient.post(url, formData, { params: params }).pipe(
      map((res: { id: string }) => ({ imageId: res.id, cId: payload.cId })),
      catchError((error: HttpErrorResponse) =>
        throwError(() => ({
          msg: error.error.message,
          fileName: payload.file.name,
          cId: payload.cId
        }))
      )
    );
  }

  uploadDocuments(
    file: File,
    accountId: number,
    cId: string,
    purpose = MediaPurposeEnum.Verification
  ): Observable<IUploadFileRes> {
    const endpoint = `${this.#baseUrl}/documents`;
    const params = new HttpParams()
      .set('purpose', purpose)
      .set('account_id', accountId.toString());

    const formData = createFormData(file);

    return this.#httpClient
      .post(endpoint, formData, {
        params: params,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map((event) =>
          getEventMessage(event, file, cId, this.#translateService)
        ),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => ({ ...error, cId: cId }));
        })
      );
  }

  getImageById(
    imageId: string,
    thumbName: ThumbNameEnum
  ): Observable<ImageModel> {
    const url = `${this.#baseUrl}/image/${imageId}`;
    const params = new HttpParams().set('thumb_name', thumbName);

    return this.#httpClient.get(url, { params }).pipe(
      map((json) => ImageModel.fromJson(json)),
      catchError((error: HttpErrorResponse) => of(error.error))
    );
  }

  getOriginalImage(value: string[]): Observable<ImageModel> {
    const url = `${this.#baseUrl}/images/_bulk`;

    const body = {
      ids: value,
      thumb_name: 'original'
    };

    return this.#httpClient.post<ImageModel>(url, body);
  }

  adjustImage(imageId: string, formData: FormData) {
    const url = `${this.#baseUrl}/image/${imageId}/_adjust`;

    return this.#httpClient.post(url, formData);
  }

  getPromoBanner(): Observable<PromoBannerModel[]> {
    const url = `${environment.apiUrl}/promo-banner/public`;
    return this.#httpClient
      .get<PromoBannerModel[]>(url)
      .pipe(
        map((json: PromoBannerModel[]) =>
          json.map((item) => PromoBannerModel.fromJSON(item))
        )
      );
  }

  uploadImage({
    file,
    purpose,
    cId,
    accountId
  }: UploadProfileImageReqPayload): Observable<IUploadFileRes> {
    const endpoint = `${this.#baseUrl}/images`;
    const params = new HttpParams()
      .set('purpose', purpose ?? MediaPurposeEnum.Profile)
      .set('account_id', accountId.toString());

    const formData = createFormData(file);

    return this.#httpClient
      .post(endpoint, formData, {
        params: params,
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map((event) =>
          getEventMessage(event, file, cId, this.#translateService)
        ),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => ({ error, cId: cId }));
        })
      );
  }
}
